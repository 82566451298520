<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <template v-if="display">
                <button
                  v-if="isSaveButtonVisible"
                  type="button"
                  class="btn btn-success"
                  :disabled="saving"
                  @click="save"
                >
                  <i class="fa fa-save "></i> {{ $t('buttons.save') }}
                </button>
                <app-button-delete-with-usages
                  v-if="mode === 'update'"
                  @deleteRecord="deleteCar"
                  @load-data="checkUsages"
                  @page-change="setUsagesPageAndGetRecords"
                  :data="usages.data"
                  :total-count="usages.totalCount"
                  :page="usages.page"
                  :showLoader="usages.showLoader"
                  :config="usagesDataTableConfig"
                  :showDeleteButton="isDeleteButtonVisible"
                ></app-button-delete-with-usages>
              </template>
              <app-button-close route-name="car_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <section v-if="display" class="content form-horizontal">
        <div class="row">
          <div class="col-lg-6">
            <div class="card card-body">
              <input type="hidden" v-model="car.id">
              <app-select
                v-model="car.type"
                :options="carTypeValues"
                id="car_type"
                :label="$t('car.type')"
                :noEmptyValue="true"
                :required="true"
              >
              </app-select>
              <app-input
                v-model="car.title"
                @blur="$v.car.title.$touch()"
                :error="$v.car.title.$error"
                id="car_title"
                :label="$t('car.title')"
                :required="true"
              >
              </app-input>
              <app-advanced-input
                v-model="car.brand"
                @blur="$v.car.brand.$touch()"
                :error="$v.car.brand.$error"
                id="car_brand"
                :label="$t('car.brand')"
                :required="true"
                showActions
                :actions="brandFillActions"
                @input-action-clicked="preFillBrand($event)"
              >
              </app-advanced-input>
              <app-input
                v-model="car.model"
                @blur="$v.car.model.$touch()"
                :error="$v.car.model.$error"
                id="car_model"
                :label="$t('car.model')"
                :required="true"
              >
              </app-input>
              <app-input
                v-model="car.productionYear"
                @blur="$v.car.productionYear.$touch()"
                :error="$v.car.productionYear.$error"
                id="car_productionYear"
                :label="$t('car.productionYear')"
                :required="true"
                type="number"
                :min="0"
              >
              </app-input>
              <app-textarea
                v-model="car.description"
                id="car_description"
                :label="$t('car.description')"
                :maxlength="1024"
                @blur="$v.car.description.$touch()"
                :error="$v.car.description.$error"
              >
              </app-textarea>
              <div class="form-group">
                <label>{{ $t('car.image') }}</label><br>
                <app-media-select-button
                  @set-media="setImage"
                >
                </app-media-select-button>
                <app-media-upload-button
                  @set-media="setImage"
                >
                </app-media-upload-button>
              </div>
              <div class="form-group">
                <app-media-editable
                  v-if="carImage"
                  :media="carImage"
                  :width="360"
                  :height="202"
                  @set-media="setImage"
                  @remove-media="removeImage"
                >
                </app-media-editable>
              </div>
            </div>
            <div class="card card-body">
              <h4>{{ $t('car.engine.header') }}</h4>
              <table class="test-car">
                <tbody>
                <tr>
                  <th>
                    <label for="car_engine_fuel">
                      {{ $t('car.engine.fuel') }}
                    </label>
                  </th>
                  <td colspan="4">
                    <app-advanced-input
                      v-model="car.engine.fuel"
                      @blur="$v.car.engine.fuel.$touch()"
                      :error="$v.car.engine.fuel.$error"
                      id="car_engine_fuel"
                      noLabel
                      showActions
                      :actions="fuelFillActions"
                      @input-action-clicked="preFillFuel($event)"
                    >
                    </app-advanced-input>
                  </td>
                </tr>
                <tr>
                  <th>
                    <label for="car_engine_drive">
                      {{ $t('car.engine.drive') }}
                    </label>
                  </th>
                  <td colspan="4">
                    <app-advanced-input
                      v-model="car.engine.drive"
                      @blur="$v.car.engine.drive.$touch()"
                      :error="$v.car.engine.drive.$error"
                      id="car_engine_drive"
                      noLabel
                      showActions
                      :actions="driveFillActions"
                      @input-action-clicked="preFillDrive($event)"
                    >
                    </app-advanced-input>
                  </td>
                </tr>
                <tr>
                  <th>
                    <label for="car_engine_gearbox">
                      {{ $t('car.engine.gearbox') }}
                    </label>
                  </th>
                  <td colspan="4">
                    <app-input
                      v-model="car.engine.gearbox"
                      @blur="$v.car.engine.gearbox.$touch()"
                      :error="$v.car.engine.gearbox.$error"
                      id="car_engine_gearbox"
                      noLabel
                    >
                    </app-input>
                  </td>
                </tr>
                <template v-if="carTypeIsHybrid(car.type) || carTypeIsPetrolDiesel(car.type)">
                  <tr>
                    <th colspan="5">
                      <label>
                        <strong>
                          {{ $t('car.petrol_motor') }} / {{ $t('car.diesel_motor') }}:
                        </strong>
                      </label>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_fuelEngine_cylinderSetup">
                        {{ $t('car.fuelEngine.cylinderSetup') }}
                      </label>
                    </th>
                    <td colspan="4">
                      <app-input
                        v-model="car.fuelEngine.cylinderSetup"
                        @blur="$v.car.fuelEngine.cylinderSetup.$touch()"
                        :error="$v.car.fuelEngine.cylinderSetup.$error"
                        id="car_fuelEngine_cylinderSetup"
                        noLabel
                      >
                      </app-input>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_fuelEngine_cylinderVolume">
                        {{ $t('car.fuelEngine.cylinderVolume') }}
                      </label>
                    </th>
                    <td colspan="3">
                      <app-input
                        v-model="car.fuelEngine.cylinderVolume"
                        @blur="$v.car.fuelEngine.cylinderVolume.$touch()"
                        :error="$v.car.fuelEngine.cylinderVolume.$error"
                        id="car_fuelEngine_cylinderVolume"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.cylinderVolume') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_fuelEngine_maxPower">
                        {{ $t('car.fuelEngine.maxPower') }}
                      </label>
                    </th>
                    <td colspan="3">
                      <app-input
                        v-model="car.fuelEngine.maxPower"
                        @blur="$v.car.fuelEngine.maxPower.$touch()"
                        :error="$v.car.fuelEngine.maxPower.$error"
                        id="car_fuelEngine_maxPower"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.power') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_fuelEngine_maxPowerRotationFrom">
                        {{ $t('car.fuelEngine.maxPowerRotation') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.fuelEngine.maxPowerRotationFrom"
                        @blur="$v.car.fuelEngine.maxPowerRotationFrom.$touch()"
                        :error="$v.car.fuelEngine.maxPowerRotationFrom.$error"
                        id="car_fuelEngine_maxPowerRotationFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.fuelEngine.maxPowerRotationTo"
                        @blur="$v.car.fuelEngine.maxPowerRotationTo.$touch()"
                        :error="$v.car.fuelEngine.maxPowerRotationTo.$error"
                        id="car_fuelEngine_maxPowerRotationTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.rotation') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_fuelEngine_maxTorque">
                        {{ $t('car.fuelEngine.maxTorque') }}
                      </label>
                    </th>
                    <td colspan="3">
                      <app-input
                        v-model="car.fuelEngine.maxTorque"
                        @blur="$v.car.fuelEngine.maxTorque.$touch()"
                        :error="$v.car.fuelEngine.maxTorque.$error"
                        id="car_fuelEngine_maxTorque"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.rotation') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_fuelEngine_maxTorqueRotationFrom">
                        {{ $t('car.fuelEngine.maxTorqueRotation') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.fuelEngine.maxTorqueRotationFrom"
                        @blur="$v.car.fuelEngine.maxTorqueRotationFrom.$touch()"
                        :error="$v.car.fuelEngine.maxTorqueRotationFrom.$error"
                        id="car_fuelEngine_maxTorqueRotationFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.fuelEngine.maxTorqueRotationTo"
                        @blur="$v.car.fuelEngine.maxTorqueRotationTo.$touch()"
                        :error="$v.car.fuelEngine.maxTorqueRotationTo.$error"
                        id="car_fuelEngine_maxTorqueRotationTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.rotation') }}</td>
                  </tr>
                </template>
                <template v-if="carTypeIsHybrid(car.type) || carTypeIsElectro(car.type)">
                  <tr>
                    <th colspan="5">
                      <label>
                        <strong>{{ $t('car.electro_motor') }}:</strong>
                      </label>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_elEngine_motorType">
                        {{ $t('car.elEngine.motorType') }}
                      </label>
                    </th>
                    <td colspan="4">
                      <app-input
                        v-model="car.elEngine.motorType"
                        @blur="$v.car.elEngine.motorType.$touch()"
                        :error="$v.car.elEngine.motorType.$error"
                        id="car_elEngine_motorType"
                        noLabel
                      >
                      </app-input>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_elEngine_maxPower">
                        {{ $t('car.elEngine.maxPower') }}
                      </label>
                    </th>
                    <td colspan="3">
                      <app-input
                        v-model="car.elEngine.maxPower"
                        @blur="$v.car.elEngine.maxPower.$touch()"
                        :error="$v.car.elEngine.maxPower.$error"
                        id="car_elEngine_maxPower"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.power') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_elEngine_maxTorque">
                        {{ $t('car.elEngine.maxTorque') }}
                      </label>
                    </th>
                    <td colspan="3">
                      <app-input
                        v-model="car.elEngine.maxTorque"
                        @blur="$v.car.elEngine.maxTorque.$touch()"
                        :error="$v.car.elEngine.maxTorque.$error"
                        id="car_elEngine_maxTorque"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.torque') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_elEngine_maxRotation">
                        {{ $t('car.elEngine.maxRotation') }}
                      </label>
                    </th>
                    <td colspan="3">
                      <app-input
                        v-model="car.elEngine.maxRotation"
                        @blur="$v.car.elEngine.maxRotation.$touch()"
                        :error="$v.car.elEngine.maxRotation.$error"
                        id="car_elEngine_maxRotation"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.rotation') }}</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <div class="card card-body">
              <h4>{{ $t('car.measurements.header') }}</h4>
              <table class="test-car">
                <tbody>
                <tr>
                  <th>
                    <label for="car_measurements_length">
                      {{ $t('car.measurements.length') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.measurements.length"
                      @blur="$v.car.measurements.length.$touch()"
                      :error="$v.car.measurements.length.$error"
                      id="car_measurements_length"
                      type="number"
                      :min="0"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.dimensions') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_measurements_width">
                      {{ $t('car.measurements.width') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.measurements.width"
                      @blur="$v.car.measurements.width.$touch()"
                      :error="$v.car.measurements.width.$error"
                      id="car_measurements_width"
                      type="number"
                      :min="0"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.dimensions') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_measurements_height">
                      {{ $t('car.measurements.height') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.measurements.height"
                      @blur="$v.car.measurements.height.$touch()"
                      :error="$v.car.measurements.height.$error"
                      id="car_measurements_height"
                      type="number"
                      :min="0"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.dimensions') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_measurements_wheelBase">
                      {{ $t('car.measurements.wheelBase') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.measurements.wheelBase"
                      @blur="$v.car.measurements.wheelBase.$touch()"
                      :error="$v.car.measurements.wheelBase.$error"
                      id="car_measurements_wheelBase"
                      type="number"
                      :min="0"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.dimensions') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_measurements_curbWeight">
                      {{ $t('car.measurements.curbWeight') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.measurements.curbWeight"
                      @blur="$v.car.measurements.curbWeight.$touch()"
                      :error="$v.car.measurements.curbWeight.$error"
                      id="car_measurements_curbWeight"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.weight') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_measurements_curbWeightEu">
                      {{ $t('car.measurements.curbWeightEu') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.measurements.curbWeightEu"
                      @blur="$v.car.measurements.curbWeightEu.$touch()"
                      :error="$v.car.measurements.curbWeightEu.$error"
                      id="car_measurements_curbWeightEu"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.weight') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_measurements_capacityWeight">
                      {{ $t('car.measurements.capacityWeight') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.measurements.capacityWeight"
                      @blur="$v.car.measurements.capacityWeight.$touch()"
                      :error="$v.car.measurements.capacityWeight.$error"
                      id="car_measurements_capacityWeight"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.weight') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_measurements_totalWeight">
                      {{ $t('car.measurements.totalWeight') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.measurements.totalWeight"
                      @blur="$v.car.measurements.totalWeight.$touch()"
                      :error="$v.car.measurements.totalWeight.$error"
                      id="car_measurements_totalWeight"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.weight') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_measurements_trunkCapacityFrom">
                      {{ $t('car.measurements.trunkCapacity') }}
                    </label>
                  </th>
                  <td>
                    <app-input
                      v-model="car.measurements.trunkCapacityFrom"
                      @blur="$v.car.measurements.trunkCapacityFrom.$touch()"
                      :error="$v.car.measurements.trunkCapacityFrom.$error"
                      id="car_measurements_trunkCapacityFrom"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td>/</td>
                  <td>
                    <app-input
                      v-model="car.measurements.trunkCapacityTo"
                      @blur="$v.car.measurements.trunkCapacityTo.$touch()"
                      :error="$v.car.measurements.trunkCapacityTo.$error"
                      id="car_measurements_trunkCapacityTo"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.volume') }}</td>
                </tr>
                <tr v-if="carTypeIsHybrid(car.type) || carTypeIsPetrolDiesel(car.type)">
                  <th>
                    <label for="car_measurements_tankVolume">
                      {{ $t('car.measurements.tankVolume') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.measurements.tankVolume"
                      @blur="$v.car.measurements.tankVolume.$touch()"
                      :error="$v.car.measurements.tankVolume.$error"
                      id="car_measurements_tankVolume"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.volume') }}</td>
                </tr>
                <tr v-if="carTypeIsHybrid(car.type) || carTypeIsElectro(car.type)">
                  <th>
                    <label for="car_measurements_batteryCapacity">
                      {{ $t('car.measurements.batteryCapacity') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.measurements.batteryCapacity"
                      @blur="$v.car.measurements.batteryCapacity.$touch()"
                      :error="$v.car.measurements.batteryCapacity.$error"
                      id="car_measurements_batteryCapacity"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.capacity') }}</td>
                </tr>
                </tbody>
              </table>
              <table class="test-car">
                <tbody>
                <tr>
                  <th>
                    <label for="car_measurements_trunkLength">
                      {{ $t('car.measurements.trunkDimensions') }}
                    </label>
                  </th>
                  <td>
                    <app-input
                      v-model="car.measurements.trunkLength"
                      @blur="$v.car.measurements.trunkLength.$touch()"
                      :error="$v.car.measurements.trunkLength.$error"
                      id="car_measurements_trunkLength"
                      type="number"
                      :min="0"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td>x</td>
                  <td>
                    <app-input
                      v-model="car.measurements.trunkWidth"
                      @blur="$v.car.measurements.trunkWidth.$touch()"
                      :error="$v.car.measurements.trunkWidth.$error"
                      id="car_measurements_trunkWidth"
                      type="number"
                      :min="0"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td>x</td>
                  <td>
                    <app-input
                      v-model="car.measurements.trunkHeight"
                      @blur="$v.car.measurements.trunkHeight.$touch()"
                      :error="$v.car.measurements.trunkHeight.$error"
                      id="car_measurements_trunkHeight"
                      type="number"
                      :min="0"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.dimensions') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card card-body">
              <h4>{{ $t('car.driving.header') }}</h4>
              <table class="test-car">
                <tbody>
                <tr>
                  <th>
                    <label for="car_driving_maxSpeed">
                      {{ $t('car.driving.maxSpeed') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.driving.maxSpeed"
                      @blur="$v.car.driving.maxSpeed.$touch()"
                      :error="$v.car.driving.maxSpeed.$error"
                      id="car_driving_maxSpeed"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.speed') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_driving_acceleration">
                      {{ $t('car.driving.acceleration') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.driving.acceleration"
                      @blur="$v.car.driving.acceleration.$touch()"
                      :error="$v.car.driving.acceleration.$error"
                      id="car_driving_acceleration"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.acceleration') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="card card-body">
              <h4>{{ $t('car.consumption.header') }}</h4>
              <table class="test-car">
                <tbody>
                <template v-if="carTypeIsHybrid(car.type) || carTypeIsPetrolDiesel(car.type)">
                  <tr>
                    <th colspan="5">
                      <label>
                        <strong>
                          {{ $t('car.petrol_motor') }} / {{ $t('car.diesel_motor') }}:
                        </strong>
                      </label>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_fuelConsumption_extraHighFrom">
                        {{ $t('car.fuelConsumption.extraHigh') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.extraHighFrom"
                        @blur="$v.car.fuelConsumption.extraHighFrom.$touch()"
                        :error="$v.car.fuelConsumption.extraHighFrom.$error"
                        id="car_fuelConsumption_extraHighFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.extraHighTo"
                        @blur="$v.car.fuelConsumption.extraHighTo.$touch()"
                        :error="$v.car.fuelConsumption.extraHighTo.$error"
                        id="car_fuelConsumption_extraHighTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.fuel_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_fuelConsumption_highFrom">
                        {{ $t('car.fuelConsumption.high') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.highFrom"
                        @blur="$v.car.fuelConsumption.highFrom.$touch()"
                        :error="$v.car.fuelConsumption.highFrom.$error"
                        id="car_fuelConsumption_highFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.highTo"
                        @blur="$v.car.fuelConsumption.highTo.$touch()"
                        :error="$v.car.fuelConsumption.highTo.$error"
                        id="car_fuelConsumption_highTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.fuel_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_fuelConsumption_mediumFrom">
                        {{ $t('car.fuelConsumption.medium') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.mediumFrom"
                        @blur="$v.car.fuelConsumption.mediumFrom.$touch()"
                        :error="$v.car.fuelConsumption.mediumFrom.$error"
                        id="car_fuelConsumption_mediumFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.mediumTo"
                        @blur="$v.car.fuelConsumption.mediumTo.$touch()"
                        :error="$v.car.fuelConsumption.mediumTo.$error"
                        id="car_fuelConsumption_mediumTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.fuel_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_fuelConsumption_lowFrom">
                        {{ $t('car.fuelConsumption.low') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.lowFrom"
                        @blur="$v.car.fuelConsumption.lowFrom.$touch()"
                        :error="$v.car.fuelConsumption.lowFrom.$error"
                        id="car_fuelConsumption_lowFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.lowTo"
                        @blur="$v.car.fuelConsumption.lowTo.$touch()"
                        :error="$v.car.fuelConsumption.lowTo.$error"
                        id="car_fuelConsumption_lowTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.fuel_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_fuelConsumption_combinedFrom">
                        {{ $t('car.fuelConsumption.combined') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.combinedFrom"
                        @blur="$v.car.fuelConsumption.combinedFrom.$touch()"
                        :error="$v.car.fuelConsumption.combinedFrom.$error"
                        id="car_fuelConsumption_combinedFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.combinedTo"
                        @blur="$v.car.fuelConsumption.combinedTo.$touch()"
                        :error="$v.car.fuelConsumption.combinedTo.$error"
                        id="car_fuelConsumption_combinedTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.fuel_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_fuelConsumption_testedCityFrom">
                        {{ $t('car.fuelConsumption.testedCity') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.testedCityFrom"
                        @blur="$v.car.fuelConsumption.testedCityFrom.$touch()"
                        :error="$v.car.fuelConsumption.testedCityFrom.$error"
                        id="car_fuelConsumption_testedCityFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.testedCityTo"
                        @blur="$v.car.fuelConsumption.testedCityTo.$touch()"
                        :error="$v.car.fuelConsumption.testedCityTo.$error"
                        id="car_fuelConsumption_testedCityTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.fuel_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_fuelConsumption_testedMotorwayFrom">
                        {{ $t('car.fuelConsumption.testedMotorway') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.testedMotorwayFrom"
                        @blur="$v.car.fuelConsumption.testedMotorwayFrom.$touch()"
                        :error="$v.car.fuelConsumption.testedMotorwayFrom.$error"
                        id="car_fuelConsumption_testedMotorwayFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.testedMotorwayTo"
                        @blur="$v.car.fuelConsumption.testedMotorwayTo.$touch()"
                        :error="$v.car.fuelConsumption.testedMotorwayTo.$error"
                        id="car_fuelConsumption_testedMotorwayTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.fuel_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_fuelConsumption_testedCountryRoadFrom">
                        {{ $t('car.fuelConsumption.testedCountryRoad') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.testedCountryRoadFrom"
                        @blur="$v.car.fuelConsumption.testedCountryRoadFrom.$touch()"
                        :error="$v.car.fuelConsumption.testedCountryRoadFrom.$error"
                        id="car_fuelConsumption_testedCountryRoadFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.fuelConsumption.testedCountryRoadTo"
                        @blur="$v.car.fuelConsumption.testedCountryRoadTo.$touch()"
                        :error="$v.car.fuelConsumption.testedCountryRoadTo.$error"
                        id="car_fuelConsumption_testedCountryRoadTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.fuel_consumption') }}</td>
                  </tr>
                </template>
                <template v-if="carTypeIsElectro(car.type)">
                  <tr>
                    <th colspan="5">
                      <label>
                        <strong>{{ $t('car.electro_motor') }}:</strong>
                      </label>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_elConsumption_extraHighFrom">
                        {{ $t('car.elConsumption.extraHigh') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.elConsumption.extraHighFrom"
                        @blur="$v.car.elConsumption.extraHighFrom.$touch()"
                        :error="$v.car.elConsumption.extraHighFrom.$error"
                        id="car_elConsumption_extraHighFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.elConsumption.extraHighTo"
                        @blur="$v.car.elConsumption.extraHighTo.$touch()"
                        :error="$v.car.elConsumption.extraHighTo.$error"
                        id="car_elConsumption_extraHighTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.el_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_elConsumption_highFrom">
                        {{ $t('car.elConsumption.high') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.elConsumption.highFrom"
                        @blur="$v.car.elConsumption.highFrom.$touch()"
                        :error="$v.car.elConsumption.highFrom.$error"
                        id="car_elConsumption_highFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.elConsumption.highTo"
                        @blur="$v.car.elConsumption.highTo.$touch()"
                        :error="$v.car.elConsumption.highTo.$error"
                        id="car_elConsumption_highTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.el_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_elConsumption_mediumFrom">
                        {{ $t('car.elConsumption.medium') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.elConsumption.mediumFrom"
                        @blur="$v.car.elConsumption.mediumFrom.$touch()"
                        :error="$v.car.elConsumption.mediumFrom.$error"
                        id="car_elConsumption_mediumFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.elConsumption.mediumTo"
                        @blur="$v.car.elConsumption.mediumTo.$touch()"
                        :error="$v.car.elConsumption.mediumTo.$error"
                        id="car_elConsumption_mediumTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.el_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_elConsumption_lowFrom">
                        {{ $t('car.elConsumption.low') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.elConsumption.lowFrom"
                        @blur="$v.car.elConsumption.lowFrom.$touch()"
                        :error="$v.car.elConsumption.lowFrom.$error"
                        id="car_elConsumption_lowFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.elConsumption.lowTo"
                        @blur="$v.car.elConsumption.lowTo.$touch()"
                        :error="$v.car.elConsumption.lowTo.$error"
                        id="car_elConsumption_lowTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.el_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_elConsumption_combinedFrom">
                        {{ $t('car.elConsumption.combined') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.elConsumption.combinedFrom"
                        @blur="$v.car.elConsumption.combinedFrom.$touch()"
                        :error="$v.car.elConsumption.combinedFrom.$error"
                        id="car_elConsumption_combinedFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.elConsumption.combinedTo"
                        @blur="$v.car.elConsumption.combinedTo.$touch()"
                        :error="$v.car.elConsumption.combinedTo.$error"
                        id="car_elConsumption_combinedTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.el_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_elConsumption_testedCityFrom">
                        {{ $t('car.elConsumption.testedCity') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.elConsumption.testedCityFrom"
                        @blur="$v.car.elConsumption.testedCityFrom.$touch()"
                        :error="$v.car.elConsumption.testedCityFrom.$error"
                        id="car_elConsumption_testedCityFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.elConsumption.testedCityTo"
                        @blur="$v.car.elConsumption.testedCityTo.$touch()"
                        :error="$v.car.elConsumption.testedCityTo.$error"
                        id="car_elConsumption_testedCityTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.el_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_elConsumption_testedMotorwayFrom">
                        {{ $t('car.elConsumption.testedMotorway') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.elConsumption.testedMotorwayFrom"
                        @blur="$v.car.elConsumption.testedMotorwayFrom.$touch()"
                        :error="$v.car.elConsumption.testedMotorwayFrom.$error"
                        id="car_elConsumption_testedMotorwayFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.elConsumption.testedMotorwayTo"
                        @blur="$v.car.elConsumption.testedMotorwayTo.$touch()"
                        :error="$v.car.elConsumption.testedMotorwayTo.$error"
                        id="car_elConsumption_testedMotorwayTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.el_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_elConsumption_testedCountryRoadFrom">
                        {{ $t('car.elConsumption.testedCountryRoad') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.elConsumption.testedCountryRoadFrom"
                        @blur="$v.car.elConsumption.testedCountryRoadFrom.$touch()"
                        :error="$v.car.elConsumption.testedCountryRoadFrom.$error"
                        id="car_elConsumption_testedCountryRoadFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.elConsumption.testedCountryRoadTo"
                        @blur="$v.car.elConsumption.testedCountryRoadTo.$touch()"
                        :error="$v.car.elConsumption.testedCountryRoadTo.$error"
                        id="car_elConsumption_testedCountryRoadTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.el_consumption') }}</td>
                  </tr>
                  <tr>
                    <th>
                      <label for="car_elConsumption_rangeFrom">
                        {{ $t('car.elConsumption.range') }}
                      </label>
                    </th>
                    <td>
                      <app-input
                        v-model="car.elConsumption.rangeFrom"
                        @blur="$v.car.elConsumption.rangeFrom.$touch()"
                        :error="$v.car.elConsumption.rangeFrom.$error"
                        id="car_elConsumption_rangeFrom"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td>-</td>
                    <td>
                      <app-input
                        v-model="car.elConsumption.rangeTo"
                        @blur="$v.car.elConsumption.rangeTo.$touch()"
                        :error="$v.car.elConsumption.rangeTo.$error"
                        id="car_elConsumption_rangeTo"
                        type="number"
                        :min="0"
                        :step="0.1"
                        noLabel
                      >
                      </app-input>
                    </td>
                    <td class="unit">{{ $t('car.unit.range') }}</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <div class="card card-body" v-if="carTypeIsHybrid(car.type) || carTypeIsPetrolDiesel(car.type)">
              <h4>{{ $t('car.emission.header') }}</h4>
              <table class="test-car">
                <tbody>
                <tr>
                  <th>
                    <label for="car_emission_valueFrom">
                      {{ $t('car.emission.value') }}
                    </label>
                  </th>
                  <td>
                    <app-input
                      v-model="car.emission.valueFrom"
                      @blur="$v.car.emission.valueFrom.$touch()"
                      :error="$v.car.emission.valueFrom.$error"
                      id="car_emission_valueFrom"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td>-</td>
                  <td>
                    <app-input
                      v-model="car.emission.valueTo"
                      @blur="$v.car.emission.valueTo.$touch()"
                      :error="$v.car.emission.valueTo.$error"
                      id="car_emission_valueTo"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.emission') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_emission_standard">
                      {{ $t('car.emission.standard') }}
                    </label>
                  </th>
                  <td colspan="4">
                    <app-advanced-input
                      v-model="car.emission.standard"
                      @blur="$v.car.emission.standard.$touch()"
                      :error="$v.car.emission.standard.$error"
                      id="car_emission_standard"
                      noLabel
                      showActions
                      :actions="emissionFillActions"
                      @input-action-clicked="preFillEmission($event)"
                    >
                    </app-advanced-input>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="card card-body">
              <h4>{{ $t('car.price.header') }}</h4>
              <table class="test-car">
                <tbody>
                <tr>
                  <th>
                    <label for="car_price_modelPrice">
                      {{ $t('car.price.modelPrice') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.price.modelPrice"
                      @blur="$v.car.price.modelPrice.$touch()"
                      :error="$v.car.price.modelPrice.$error"
                      id="car_price_modelPrice"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.price') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_price_versionPrice">
                      {{ $t('car.price.versionPrice') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.price.versionPrice"
                      @blur="$v.car.price.versionPrice.$touch()"
                      :error="$v.car.price.versionPrice.$error"
                      id="car_price_versionPrice"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.price') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_price_testedPrice">
                      {{ $t('car.price.testedPrice') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.price.testedPrice"
                      @blur="$v.car.price.testedPrice.$touch()"
                      :error="$v.car.price.testedPrice.$error"
                      id="car_price_testedPrice"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.price') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="card card-body">
              <h4>{{ $t('car.rating.header') }}</h4>
              <table class="test-car">
                <tbody>
                <tr>
                  <th>
                    <label for="car_rating_exterior">
                      {{ $t('car.rating.exterior') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.rating.exterior"
                      @blur="$v.car.rating.exterior.$touch()"
                      :error="$v.car.rating.exterior.$error"
                      id="car_rating_exterior"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.percentage') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_rating_interior">
                      {{ $t('car.rating.interior') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.rating.interior"
                      @blur="$v.car.rating.interior.$touch()"
                      :error="$v.car.rating.interior.$error"
                      id="car_rating_interior"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.percentage') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_rating_engine">
                      {{ $t('car.rating.engine') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.rating.engine"
                      @blur="$v.car.rating.engine.$touch()"
                      :error="$v.car.rating.engine.$error"
                      id="car_rating_engine"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.percentage') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_rating_driving">
                      {{ $t('car.rating.driving') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.rating.driving"
                      @blur="$v.car.rating.driving.$touch()"
                      :error="$v.car.rating.driving.$error"
                      id="car_rating_driving"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.percentage') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_rating_price">
                      {{ $t('car.rating.price') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-input
                      v-model="car.rating.price"
                      @blur="$v.car.rating.price.$touch()"
                      :error="$v.car.rating.price.$error"
                      id="car_rating_price"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                    >
                    </app-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.percentage') }}</td>
                </tr>
                <tr>
                  <th>
                    <label for="car_rating_total">
                      {{ $t('car.rating.total') }}
                    </label>
                  </th>
                  <td colspan="3">
                    <app-advanced-input
                      v-model="car.rating.total"
                      @blur="$v.car.rating.total.$touch()"
                      :error="$v.car.rating.total.$error"
                      id="car_rating_total"
                      type="number"
                      :min="0"
                      :step="0.1"
                      noLabel
                      showButtons
                      :buttons="inputCalculateTotalButtons"
                      @input-button-clicked="calculateTotal($event)"
                    >
                    </app-advanced-input>
                  </td>
                  <td class="unit">{{ $t('car.unit.percentage') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="card card-body">
              <h4>{{ $t('car.prosCons.header') }}</h4>
              <table class="test-car pros-cons">
                <tr>
                  <th colspan="5">
                    <label>
                      <strong>{{ $t('car.prosCons.pros') }}:</strong>
                    </label>
                  </th>
                </tr>
                <tr v-for="(pro, proIndex) in car.prosCons.pros" :key="`item-${proIndex}`">
                  <td>
                    <app-advanced-input
                      v-model="car.prosCons.pros[proIndex]"
                      showButtons
                      :buttons="inputDeleteButtons"
                      @input-button-clicked="removePro($event, proIndex)"
                    >
                    </app-advanced-input>
                  </td>
                </tr>
                <tr v-if="showAddProsButton" class="button-align-center">
                  <td>
                    <button class="btn btn-success" @click="addPro()"><i class="fas fa-plus"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <th colspan="5">
                    <label>
                      <strong>{{ $t('car.prosCons.cons') }}:</strong>
                    </label>
                  </th>
                </tr>
                <tr v-for="(con, conIndex) in car.prosCons.cons" :key="`item-${conIndex}`">
                  <td>
                    <app-advanced-input
                      v-model="car.prosCons.cons[conIndex]"
                      showButtons
                      :buttons="inputDeleteButtons"
                      @input-button-clicked="removeCon($event, conIndex)"
                    >
                    </app-advanced-input>
                  </td>
                </tr>
                <tr v-if="showAddConsButton" class="button-align-center">
                  <td>
                    <button class="btn btn-success" @click="addCon()"><i class="fas fa-plus"></i>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </section>
      <div v-else>
        <app-preloader></app-preloader>
      </div>
      <app-preloader-full v-if="saving"></app-preloader-full>
    </template>
  </app-module-view>
</template>

<script>
import { decimal, integer, maxLength, maxValue, minLength, minValue, required } from 'vuelidate/lib/validators'
import AdvancedInput from '../../components/form/inputs/InputAdvanced'
import Input from '../../components/form/inputs/Input'
import Select from '../../components/form/select/Select'
import Textarea from '../../components/form/Textarea'
import CarTypeMixin from '../../components/mixins/valueObject/CarTypeMixin'
import ModuleView from '../../components/ModuleView'
import Preloader from '../../components/preloader/Preloader'
import PreloaderFull from '../../components/preloader/PreloaderFull'
import ButtonClose from '../../components/shared/ButtonClose'
import ButtonDeleteWithUsages from '../../components/shared/ButtonDeleteWithUsages'
import MediaEditable from '../../components/shared/MediaEditable'
import MediaSelectButton from '../../components/shared/MediaSelectButton'
import MediaUploadButton from '../../components/shared/MediaUploadButton'
import CarModel from '../../model/CarModel'
import NotifyService from '../../services/NotifyService'
import PermissionService from '@/services/PermissionService'

const MAX_PROS_CONS = 3

export default {
  name: 'CarNewView',
  mixins: [CarTypeMixin],
  data () {
    return {
      dataLoaded: false,
      saving: false,
      car: this._.cloneDeep(CarModel),
      inputDeleteButtons: [{ class: 'btn btn-danger', html: '<i class="fas fa-trash"></i>' }],
      inputCalculateTotalButtons: [{ class: 'btn btn-primary', html: '<i class="fas fa-calculator"></i>' }],
      fuelFillActions: [this.$t('car.petrol_motor'), this.$t('car.diesel_motor'), this.$t('car.petrol_plus_gas'),
        this.$t('car.electro_motor')],
      driveFillActions: [this.$t('car.frontAxle'), this.$t('car.rearAxle'), this.$t('car.allWheels')],
      emissionFillActions: ['Euro 1', 'Euro 2', 'Euro 3', 'Euro 4', 'Euro 5', 'Euro 6'],
      brandFillActions: [
        'Toyota', 'Volkswagen', 'Ford', 'Nissan', 'Honda', 'Hyundai', 'Chevrolet', 'Kia', 'Renault', 'Mercedes',
        'Peugeot', 'BMW', 'Audi', 'Tesla', 'Mazda', 'Jeep', 'Fiat', 'Škoda'
      ],
      mode: 'create',
      usages: {},
      showAddProsButton: true,
      showAddConsButton: true,
      usagesDataTableConfig: {
        fields: {
          'field.title': this.$t('article.list.title'),
          'setting.status': this.$t('article.list.status'),
          orderDate: this.$t('article.list.order_date')
        },
        actions: {
          copyToClipboard: 'documentId',
          detail: 'article_detail'
        }
      },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.ARTICLE_TEST_CAR_PERMISSIONS
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isDeleteButtonVisible () {
      return this.car?.id && this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    },
    display () {
      if (this.mode === 'create' || (this.mode === 'update' && this.dataLoaded)) {
        return true
      }
      return false
    },
    carImage () {
      return this.$store.getters['car/image']
    }
  },
  validations: {
    car: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
      brand: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255)
      },
      model: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255)
      },
      productionYear: {
        integer,
        minValue: minValue(0),
        maxValue: maxValue(3000)
      },
      type: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(20)
      },
      description: {
        minLength: minLength(1),
        maxLength: maxLength(1024)
      },
      engine: {
        fuel: {
          maxLength: maxLength(255)
        },
        drive: {
          maxLength: maxLength(255)
        },
        gearbox: {
          maxLength: maxLength(255)
        }
      },
      fuelEngine: {
        cylinderSetup: {
          maxLength: maxLength(255)
        },
        cylinderVolume: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        maxPower: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        maxPowerRotationFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        maxPowerRotationTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        maxTorque: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        maxTorqueRotationFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(2000000000)
        },
        maxTorqueRotationTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(2000000000)
        }
      },
      elEngine: {
        motorType: {
          maxLength: maxLength(255)
        },
        maxPower: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        maxTorque: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        maxRotation: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(2000000000)
        }
      },
      measurements: {
        length: {
          integer,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        width: {
          integer,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        height: {
          integer,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        wheelBase: {
          integer,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        curbWeight: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        curbWeightEu: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        capacityWeight: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        totalWeight: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        trunkCapacityFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        trunkCapacityTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        trunkLength: {
          integer,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        trunkWidth: {
          integer,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        trunkHeight: {
          integer,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        tankVolume: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        batteryCapacity: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        }
      },
      driving: {
        maxSpeed: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        acceleration: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        }
      },
      fuelConsumption: {
        extraHighFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        extraHighTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        highFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        highTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        mediumFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        mediumTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        lowFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        lowTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        combinedFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        combinedTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        testedCityFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        testedCityTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        testedMotorwayFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        testedMotorwayTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        testedCountryRoadFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        testedCountryRoadTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        }
      },
      elConsumption: {
        extraHighFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        extraHighTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        highFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        highTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        mediumFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        mediumTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        lowFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        lowTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        combinedFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        combinedTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        testedCityFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        testedCityTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        testedMotorwayFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        testedMotorwayTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        testedCountryRoadFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        testedCountryRoadTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(999)
        },
        rangeFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        rangeTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        }
      },
      emission: {
        valueFrom: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        valueTo: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(30000)
        },
        standard: {
          maxLength: maxLength(255)
        }
      },
      price: {
        modelPrice: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(2000000000)
        },
        versionPrice: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(2000000000)
        },
        testedPrice: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(2000000000)
        }
      },
      rating: {
        exterior: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(100)
        },
        interior: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(100)
        },
        engine: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(100)
        },
        driving: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(100)
        },
        price: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(100)
        },
        total: {
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(100)
        }
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appAdvancedInput: AdvancedInput,
    appTextarea: Textarea,
    appButtonDeleteWithUsages: ButtonDeleteWithUsages,
    appButtonClose: ButtonClose,
    appMediaSelectButton: MediaSelectButton,
    appMediaUploadButton: MediaUploadButton,
    appMediaEditable: MediaEditable,
    appSelect: Select,
    appPreloader: Preloader,
    appPreloaderFull: PreloaderFull
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    calculateTotal (buttonIndex) {
      // we have only one button, so we dont need care of multiple buttons indexes
      this.car.rating.total =
        Math.round((parseInt(this.car.rating.exterior) + parseInt(this.car.rating.interior) +
          parseInt(this.car.rating.driving) + parseInt(this.car.rating.engine) + parseInt(this.car.rating.price)) / 5)
    },
    addPro () {
      if (this.car.prosCons.pros.length < MAX_PROS_CONS) {
        this.car.prosCons.pros.push('')
        if (this.car.prosCons.pros.length === MAX_PROS_CONS) {
          this.showAddProsButton = false
        }
      }
    },
    addCon () {
      if (this.car.prosCons.cons.length < MAX_PROS_CONS) {
        this.car.prosCons.cons.push('')
        if (this.car.prosCons.cons.length === MAX_PROS_CONS) {
          this.showAddConsButton = false
        }
      }
    },
    removePro (buttonIndex, dataIndex) {
      // we have only one button, so we dont need care of multiple buttons indexes
      this.car.prosCons.pros.splice(dataIndex, 1)
      if (this.car.prosCons.pros.length < MAX_PROS_CONS) {
        this.showAddProsButton = true
      }
    },
    removeCon (buttonIndex, dataIndex) {
      // we have only one button, so we dont need care of multiple buttons indexes
      this.car.prosCons.cons.splice(dataIndex, 1)
      if (this.car.prosCons.cons.length < MAX_PROS_CONS) {
        this.showAddConsButton = true
      }
    },
    checkProsConsButtons () {
      if (this.car.prosCons.pros.length >= MAX_PROS_CONS) {
        this.showAddProsButton = false
      }
      if (this.car.prosCons.cons.length >= MAX_PROS_CONS) {
        this.showAddConsButton = false
      }
    },
    preFillFuel (actionIndex) {
      this.car.engine.fuel = this.fuelFillActions[actionIndex]
    },
    preFillDrive (actionIndex) {
      this.car.engine.drive = this.driveFillActions[actionIndex]
    },
    preFillEmission (actionIndex) {
      this.car.emission.standard = this.emissionFillActions[actionIndex]
    },
    preFillBrand (actionIndex) {
      this.car.brand = this.brandFillActions[actionIndex]
    },
    setImage (medias) {
      medias.forEach(media => {
        this.$store.commit('car/storeImage', media)
      })
    },
    removeImage () {
      this.$store.commit('car/storeImage', null)
    },
    prepareRequest (car) {
      const carRequest = this._.cloneDeep(car)
      carRequest.image = null
      if (this.$store.getters['car/image']) {
        carRequest.image = this.$store.getters['car/image'].id
      }
      carRequest.prosCons.pros = carRequest.prosCons.pros.filter(pro => pro.trim().length > 0)
      carRequest.prosCons.cons = carRequest.prosCons.cons.filter(con => con.trim().length > 0)
      return carRequest
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.saving = true
        this.$store.dispatch('car/create', this.prepareRequest(this.car))
          .then(() => {
            this.saving = false
            if (this.$store.getters['car/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.car = this.$store.getters['car/detail']
              this.$router.push('/articleTest/car/' + this.car.id + '/edit')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['car/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    checkUsages () {
      this.$store.commit('car/setUsagesId', this.car.id)
      this.usages = { page: 1, totalCount: 0, data: [], showLoader: true }
      this.getUsagesList()
    },
    getUsagesList () {
      this.usages.showLoader = true
      this.$store.dispatch('car/fetchUsages')
        .then(() => {
          this.usages.data = this.$store.getters['car/usagesList']
          this.usages.totalCount = this.$store.getters['car/usagesTotalCount']
          this.usages.page = this.$store.getters['car/usagesPage']
          this.usages.showLoader = false
        })
    },
    setUsagesPageAndGetRecords (page) {
      this.$store.commit('car/setUsagesPage', page)
      this.getUsagesList()
    },
    deleteCar () {
      if (this.usages.totalCount > 0) {
        NotifyService.setErrorMessage(this.$t('notify.record_not_deleted'))
        return
      }
      this.$store.dispatch('car/deleteRecord', this.car)
        .then(() => {
          if (this.$store.getters['car/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/articleTest/car')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['car/error'])
          }
        })
        .catch(error => console.log(error))
    },
    getCarTypeByValue (value) {
      const type = this.carTypeValues.find(item => item.id === value)
      if (type) {
        return type.title
      }
      return value
    }
  },
  created () {
    this.$store.commit('car/storeImage', null)
  }
}
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
table {
  td {
    vertical-align: middle;

    &.unit {
      width: 15%;
      text-align: center;
      font-size: 0.85rem;
    }

    &:first-child {
      width: 30%;
    }

    input.form-control {
      text-align: right !important;
    }

    .form-group {
      margin-bottom: 5px;
    }
  }

  th {
    label:first-letter {
      text-transform: capitalize;
    }

    &:first-child {
      width: 30%;
    }
  }

  tr.button-align-center {
    text-align: center;
  }

  &.pros-cons {
    td {
      input.form-control {
        text-align: left !important;
      }
    }
  }
}
</style>

<style lang="scss">
table.test-car {
  td {
    input.form-control {
      text-align: right !important;
    }
  }

  &.pros-cons {
    td {
      input.form-control {
        text-align: left !important;
      }
    }
  }
}
</style>
